<template>
  <div class="finish">
    <img src="../../assets/image/baicheng/finish.jpg" alt="" />
    <div class="words">
      <p class="title">恭喜你完成积极心理品质测试</p>
      <br />
      <div class="continue" v-if="isFinished === 0">
        <p>现在可以电脑登录</p>
        <p class="site">panda.tsingpsy.com</p>
        <p>进行熊猫历险记游戏测试哦</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isFinished: null,
      quesNum:null
    };
  },
  created() {
    document.title = '积极心理品质测试';
    if (this.$route.query.isFinished) {
      this.isFinished = this.$route.query.isFinished;
    }
    if (this.$route.query.quesNum) {
      this.quesNum = this.$route.query.quesNum;
    }
  },
};
</script>
<style lang="scss" scoped>
.finish {
  font-family: 'SYHeiNormal';
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  img {
    width: 12rem;
    height: 12rem;
    margin-top: 6rem;
  }
  .words {
    .title {
      font-weight: 550;
      font-size: 1.1rem;
      margin-bottom: 2rem;
    }
    .continue {
      width: 13rem;
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 0 auto;
      text-align: left;
      .site {
        font-weight: 550;
        color: #289257;
        margin: 0.5rem 0;
      }
    }
  }
}
</style>
